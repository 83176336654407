import { useContext } from "react"
import {
	Container,
	Grid,
	Box,
	Hidden,
	Accordion,
	AccordionSummary,
	AccordionDetails,
} from "@mui/material"
import { makeStyles } from "makeStyles"
import Icon from "@mdi/react"
import { mdiFacebook, mdiInstagram, mdiChevronDown } from "@mdi/js"

import Link from "components/i18n/Link"
import { Phone, Email } from "components/i18n/Contact"

import useTranslation from "hooks/useTranslation"
import AppContext from "@secureo/common/context/AppContext/AppContext"

import Klarna from "svg/paymentMethods/klarna-payment.svg"
import Mastercard from "svg/paymentMethods/mastercard.svg"
import Paypal from "svg/paymentMethods/paypal.svg"
import Visa from "svg/paymentMethods/visa.svg"
import Vorkasse from "svg/paymentMethods/vorkasse.svg"
import LogoLight from "svg/logo/SafeHero-light.svg"
import LogoDark from "svg/logo/SafeHero-dark.svg"
import LionsHome from "svg/layout/lions-home.svg"

import { CountryCode } from "@secureo/common/typings/CountryCode"

const Informationen = ({ language }: { language: string }) => {
	const { t } = useTranslation("common")

	return (
		<ul>
			<li>
				<Link prefetch={false} href="/privacy-policy">
					{t("privacy")}
				</Link>
			</li>
			<li>
				<Link prefetch={false} href="/imprint">
					{t("imprint")}
				</Link>
			</li>
			<li>
				<Link prefetch={false} href="/help/return-shipment">
					{t("cancellationPolicy")}
				</Link>
			</li>
			<li>
				<Link prefetch={false} href="/terms-and-conditions">
					{t("gtc")}
				</Link>
			</li>
			<li>
				<Link prefetch={false} href="/help/why-choose-us">
					{t("whyWithUs")}
				</Link>
			</li>
			{language === "de" && (
				<li>
					<Link prefetch={false} href="/blog">
						Blog
					</Link>
				</li>
			)}
		</ul>
	)
}

const GutZuWissen = () => {
	const { t } = useTranslation("common")

	return (
		<ul>
			<li>
				<Link prefetch={false} href="/help/delivery">
					{t("delivery")}
				</Link>
			</li>
			<li>
				<Link prefetch={false} href="/help/assembly">
					{t("assembly")}
				</Link>
			</li>
			<li>
				<Link prefetch={false} href="/help/payments">
					{t("paymentMethods")}
				</Link>
			</li>
			{/* <li>
				<Link prefetch={false} href="/help/shipment-tracking">
					Sendungsverfolgung
				</Link>
			</li> */}
			<li>
				<Link prefetch={false} href="/help/faq">
					FAQs
				</Link>
			</li>
		</ul>
	)
}

const TopCategories = () => {
	const { t } = useTranslation("common")

	return (
		<ul>
			<li>
				<Link prefetch={false} href="/c/wertschutzschraenke">
					{t("valuableSafetyCabinets")}
				</Link>
			</li>
			<li>
				<Link prefetch={false} href="/c/digitalzylinder">
					{t("digitalCylinders")}
				</Link>
			</li>
			<li>
				<Link prefetch={false} href="/c/smart-locks">
					{t("smartLocks")}
				</Link>
			</li>
			<li>
				<Link prefetch={false} href="/c/schliesszylinder">
					{t("lockCylinders")}
				</Link>
			</li>
			<li>
				<Link prefetch={false} href="/c/alarmanlagen">
					{t("alarmSystems")}
				</Link>
			</li>
			<li>
				<Link prefetch={false} href="/c/videoueberwachung">
					{t("videoSurveillance")}
				</Link>
			</li>
			<li style={{ position: "relative", zIndex: 2 }}>
				<Link prefetch={false} href="/brands">
					{t("allBrands")}
				</Link>
			</li>
		</ul>
	)
}

const useStyles = makeStyles()((theme) => ({
	root: {
		flexGrow: 1,
		width: "100%",
	},
	footer: {
		padding: theme.spacing(2),
		background: theme.palette.secondary.main,
		paddingBottom: "50px",
		color: theme.palette.secondary.contrastText,
		position: "relative",
		height: "auto",
		"& ul": {
			listStyleType: "none",
			margin: "0",
			padding: "0",
			"& li": {
				color: theme.palette.secondary.contrastText,
				fontSize: "14px",
				padding: "5px 0px 5px 0px",
				cursor: "pointer",
				transition: "0.2s",
				textDecoration: "none",
				"&:hover": {
					color: theme.palette.primary.main,
					transition: ".1s",
				},
			},
		},
	},
	columnHeadline: {
		margin: "20px 0px 20px 0px",
	},
	// newsletter
	input: {
		marginLeft: 8,
		flex: 1,
	},
	iconButton: {
		padding: 10,
	},
	divider: {
		width: 1,
		height: 28,
		margin: 4,
	},
	// mobile
	accordion: {
		background: "none",
		color: theme.palette.secondary.contrastText,
		boxShadow: "none",
		":before": {
			backgroundColor: "none",
			boxShadow: "none",
		},
	},
	accordionHeader: {
		marginLeft: "-23px",
		fontSize: "0.9rem",
	},
	content: {
		color: theme.palette.secondary.contrastText,
	},
}))

// const getIdealoLink = (countryCode) => {
// 	switch (countryCode) {
// 		case "DE":
// 			return "https://www.idealo.de/preisvergleich/Shop/319773"
// 		case "AT":
// 			return "https://www.idealo.de/preisvergleich/Shop/300598"
// 		default:
// 			return "https://www.idealo.de/preisvergleich/Shop/319773"
// 	}
// }

const getLionsHomeLink = (countryCode) => {
	switch (countryCode) {
		case "DE":
			return "https://www.lionshome.de/"
		case "AT":
			return "https://www.lionshome.at/"
		default:
			return "https://www.lionshome.de/"
	}
}

const Footer = ({ countryCode, language }: Props) => {
	const { isBusinessVersion } = useContext(AppContext)
	const { t } = useTranslation("common")
	const { classes } = useStyles()

	// const idealoLink = getIdealoLink(countryCode)
	const lionsHomeLink = getLionsHomeLink(countryCode)

	return (
		<footer>
			<div className={classes.root}>
				<div className={classes.footer}>
					<Container>
						<Grid container spacing={3}>
							<Grid item xs={12} sm={6} md={3}>
								<Box my={2}>
									{!isBusinessVersion ? (
										<LogoLight
											style={{
												maxWidth: "160px",
												maxHeight: "70px",
												margin: "0",
												objectFit: "contain",
												position: "relative",
											}}
										/>
									) : (
										<LogoDark
											style={{
												maxWidth: "160px",
												margin: "0",
												maxHeight: "70px",
												objectFit: "contain",
												position: "relative",
											}}
										/>
									)}
								</Box>

								<ul>
									<li>
										<Phone />
									</li>
									<li>
										<Email />
									</li>
									<li>
										<Link prefetch={false} href="/help/contact">
											<Box
												p={2}
												my={2}
												style={{
													border: "1px solid #7abfac",
													borderRadius: "5px",
													maxWidth: "145px",
												}}
											>
												{t("contactUs")}
											</Box>
										</Link>
									</li>
								</ul>
							</Grid>
							<Hidden smDown>
								<Grid item xs={12} sm={6} md={3}>
									<h4 className={classes.columnHeadline}>{t("information")}</h4>
									<Informationen language={language} />
								</Grid>
								<Grid item xs={12} sm={6} md={3}>
									<h4 className={classes.columnHeadline}>{t("goodToKnow")}</h4>
									<GutZuWissen />
								</Grid>
								<Grid item xs={12} sm={6} md={3}>
									<h4 className={classes.columnHeadline}>{t("topCategories")}</h4>
									<TopCategories />
								</Grid>
							</Hidden>
						</Grid>
						<Hidden smUp>
							<Box mt={2}>
								<div className={classes.root}>
									<Accordion className={classes.accordion}>
										<AccordionSummary
											expandIcon={
												<Icon
													path={mdiChevronDown}
													size={1}
													color={
														!isBusinessVersion ? "#ffffff" : "#274041"
													}
												/>
											}
											aria-label="Expand"
											aria-controls="panel1-content"
											id="panel1-header"
											className={classes.accordionHeader}
										>
											{t("information")}
										</AccordionSummary>
										<AccordionDetails className={classes.content}>
											<Informationen language={language} />
										</AccordionDetails>
									</Accordion>
									<Accordion className={classes.accordion}>
										<AccordionSummary
											expandIcon={
												<Icon
													path={mdiChevronDown}
													size={1}
													color={
														!isBusinessVersion ? "#ffffff" : "#274041"
													}
												/>
											}
											aria-label="Expand"
											aria-controls="panel2-content"
											id="panel2-header"
											className={classes.accordionHeader}
										>
											{t("goodToKnow")}
										</AccordionSummary>
										<AccordionDetails className={classes.content}>
											<GutZuWissen />
										</AccordionDetails>
									</Accordion>
									<Accordion className={classes.accordion}>
										<AccordionSummary
											expandIcon={
												<Icon
													path={mdiChevronDown}
													size={1}
													color={
														!isBusinessVersion ? "#ffffff" : "#274041"
													}
												/>
											}
											aria-label="Expand"
											aria-controls="panel3-content"
											id="panel3-header"
											className={classes.accordionHeader}
										>
											{t("topCategories")}
										</AccordionSummary>
										<AccordionDetails className={classes.content}>
											<TopCategories />
										</AccordionDetails>
									</Accordion>
								</div>
							</Box>
						</Hidden>
						<Grid container spacing={3}>
							<Grid item xs={12} sm={6} md={9}>
								<Grid container spacing={3}>
									<Grid item xs={12} md={8}>
										<h4>{t("paymentMethods")}</h4>
										<div
											style={{
												display: "flex",
												alignContent: "flex-start",
												flexFlow: "wrap",
											}}
										>
											<Box mr={1}>
												<Klarna />
											</Box>
											<Box mr={1}>
												<Mastercard />
											</Box>
											<Box mr={1}>
												<Paypal />
											</Box>
											<Box mr={1}>
												<Visa />
											</Box>
											<Box mr={1}>
												<Vorkasse />
											</Box>
										</div>
									</Grid>
									<Grid item xs={12} md={4}>
										<h4>{t("ourPartners")}</h4>
										<div
											style={{
												display: "flex",
												alignItems: "space-between",
											}}
										>
											{/* <a
												href={idealoLink}
												target="_blank"
												rel="noopener noreferrer"
											>
												<div
													style={{
														width: "100%",
														maxWidth: 110,
														marginTop: 10,
														marginBottom: 10,
													}}
												>
													<Image
														alt="idealo"
														src="https://res.cloudinary.com/digitalzylinder-shop/image/upload/v1598962502/DigiShop2/Homepage/main_logo_weiss_auf_transparent_200px1.png"
														width={300}
														height={90}
														style={{
															maxWidth: "100%",
															height: "auto",
															objectFit: "contain",
														}}
													/>
												</div>
											</a> */}
											<a
												href={lionsHomeLink}
												target="_blank"
												rel="nooepener noreferrer"
											>
												<LionsHome
													style={{
														height: 40,
														marginLeft: 10,
													}}
												/>
											</a>
										</div>
									</Grid>
								</Grid>
							</Grid>
							<Grid item xs={12} sm={6} md={3}>
								<h4>Social Media</h4>
								<div style={{ display: "flex" }}>
									<Box>
										<a
											href="https://www.facebook.com/SafeHero-113013536815944/"
											target="_blank"
											rel="noopener noreferrer"
										>
											<Icon
												path={mdiFacebook}
												title="Facebook"
												size={1}
												color={!isBusinessVersion ? "#ffffff" : "#274041"}
											/>
										</a>
									</Box>
									<Box mx={2}>
										<a
											href="https://www.instagram.com/safe.hero/"
											target="_blank"
											rel="noopener noreferrer"
										>
											<Icon
												path={mdiInstagram}
												title="Instagram"
												size={1}
												color={!isBusinessVersion ? "#ffffff" : "#274041"}
											/>
										</a>
									</Box>
								</div>
							</Grid>
						</Grid>
					</Container>
				</div>
			</div>
		</footer>
	)
}

interface Props {
	countryCode: CountryCode
	language: string
}

export default Footer
