import { SEOPageAttributes } from "../../../typings/SEO"

const title = {
	de: "Datenschutz | SafeHero.com 🛡️",
	en: "Data privacy | SafeHero.com 🛡️",
	pl: "Ochrona danych | SafeHero.com 🛡️",
}

const description = {}

const openGraph = {}

const twitter = {}

const privacyPolicy: SEOPageAttributes = {
	title,
	description,
	openGraph,
	twitter,
}

export default privacyPolicy
