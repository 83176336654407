import { PaymentMethod } from "@secureo/common/typings/Payment"

const unzerIsEnabled = process.env.NEXT_PUBLIC_ENABLE_UNZER === "true"

export const paymentMethods: PaymentMethod[] = [
	{
		key: "prepaid",
		name: {
			de: "Vorkasse Überweisung",
			pl: "Przedpłata Przelew bankowy",
		},
		description: {
			de: "Sichern Sie sich 2% Rabatt auf Ihre ausgewählten Produkte - der Rabatt wird automatisch abgezogen.",
			pl: "Uzyskaj 2% zniżki na wybrane produkty - rabat zostanie odliczony automatycznie.",
		},
		logoUrl:
			"https://res.cloudinary.com/safehero/image/upload/v1571664491/Payment%20Icons/Vorausskasse-big_bq7zql.png",
		isSuitableForCompany: true,
		isCountryRestricted: false,
	},

	// Unzer
	{
		key: "creditCard",
		provider: unzerIsEnabled ? "unzer" : "mpay",
		name: {
			de: "Kreditkarte",
			pl: "Karta kredytowa",
		},
		description: {
			de: "Mit Visa oder MasterCard bezahlen",
			pl: "Płać kartą Visa lub MasterCard",
		},
		logoUrl:
			"https://res.cloudinary.com/safehero/image/upload/v1571664491/Payment%20Icons/Creditcard-big_lihswj.png",
		isSuitableForCompany: true,
		isCountryRestricted: false,
	},
	{
		key: "paypal",
		provider: unzerIsEnabled ? "unzer" : "mpay",
		name: {
			de: "Paypal",
			pl: "Paypal",
		},
		description: {
			de: "Für die sichere Zahlungsabwicklung werden Sie im Anschluss an unseren Zahlungsanbieter Unzer weitergeleitet.",
			pl: "W celu bezpiecznego przetworzenia płatności, zostaniesz przekazany do naszego dostawcy płatności Unzer.",
		},
		logoUrl:
			"https://res.cloudinary.com/safehero/image/upload/v1571664491/Payment%20Icons/Paypal-big_idzqfc.png",
		isSuitableForCompany: true,
		isCountryRestricted: false,
	},
	{
		key: "eps",
		provider: unzerIsEnabled ? "unzer" : "mpay",
		name: {
			de: "EPS",
		},
		description: {
			de: "Für die sichere Zahlungsabwicklung werden Sie im Anschluss an unseren Zahlungsanbieter Unzer weitergeleitet.",
		},
		logoUrl:
			"https://upload.wikimedia.org/wikipedia/commons/c/c5/Eps-%C3%9Cberweisung_Logo.svg",
		isSuitableForCompany: true,
		isCountryRestricted: true,
		includedCountryCodes: ["AT"],
		isCurrencyRestricted: true,
		includedCurrencyCodes: ["EUR"],
	},
	// {
	// 	key: "applePay",
	// 	provider: "unzer",
	// 	name: {
	// 		de: "Apple Pay",
	// 		pl: "Apple Pay",
	// 	},
	// 	description: {
	// 		de: "Für die sichere Zahlungsabwicklung werden Sie im Anschluss an unseren Zahlungsanbieter Unzer weitergeleitet.",
	// 		pl: "W celu bezpiecznego przetworzenia płatności, zostaniesz przekazany do naszego dostawcy płatności Unzer.",
	// 	},
	// 	logoUrl:
	// 		"https://upload.wikimedia.org/wikipedia/commons/thumb/b/b0/Apple_Pay_logo.svg/1200px-Apple_Pay_logo.svg.png",
	// 	isSuitableForCompany: true,
	// 	isCountryRestricted: false,
	// },
	{
		key: "przelewy24",
		provider: "unzer",
		name: {
			de: "Przelewy24",
			pl: "Przelewy24",
		},
		description: {
			de: "Für die sichere Zahlungsabwicklung werden Sie im Anschluss an unseren Zahlungsanbieter Unzer weitergeleitet.",
			pl: "W celu bezpiecznego przetworzenia płatności, zostaniesz przekazany do naszego dostawcy płatności Unzer.",
		},
		logoUrl:
			"https://res.cloudinary.com/safehero/image/upload/v1681807644/Payment%20Icons/przelewy24.png",
		isSuitableForCompany: true,
		isCountryRestricted: true,
		includedCountryCodes: ["PL"],
		isCurrencyRestricted: true,
		includedCurrencyCodes: ["PLN"],
	},

	// Klarna
	{
		key: "direct_bank_transfer",
		provider: "klarna",
		name: {
			de: "Sofortüberweisung",
			pl: "Szybki Przelew",
		},
		description: { de: "" },
		logoUrl:
			"https://res.cloudinary.com/safehero/image/upload/v1571664491/Payment%20Icons/klarna_xn18rb.png",
		isSuitableForCompany: true,
		isCountryRestricted: true,
		includedCountryCodes: ["AT", "DE", "PL"],
	},
	{
		key: "pay_now",
		provider: "klarna",
		name: {
			de: "Sofortüberweisung",
			pl: "Szybki Przelew",
		},
		description: { de: "" },
		logoUrl:
			"https://res.cloudinary.com/safehero/image/upload/v1571664491/Payment%20Icons/klarna_xn18rb.png",
		isSuitableForCompany: true,
		isCountryRestricted: true,
		includedCountryCodes: ["AT", "DE", "PL"],
	},
	{
		key: "pay_later",
		provider: "klarna",
		name: {
			de: "Rechnungskauf",
			pl: "Zakup na rachunek",
		},
		description: { de: "" },
		logoUrl:
			"https://res.cloudinary.com/safehero/image/upload/v1571664491/Payment%20Icons/klarna_xn18rb.png",
		isSuitableForCompany: true,
		isCountryRestricted: true,
		includedCountryCodes: ["AT", "DE", "PL"],
	},
	{
		key: "pay_over_time",
		provider: "klarna",
		name: {
			de: "Ratenkauf",
			pl: "Zakup na raty",
		},
		description: { de: "" },
		logoUrl:
			"https://res.cloudinary.com/safehero/image/upload/v1571664491/Payment%20Icons/klarna_xn18rb.png",
		isSuitableForCompany: true,
		isCountryRestricted: true,
		includedCountryCodes: ["AT", "DE", "PL"],
	},
]
	// TODO: remove filter after go-live of Unzer
	.filter((paymentMethod) =>
		unzerIsEnabled ? true : paymentMethod.provider !== "unzer",
	) as PaymentMethod[]
