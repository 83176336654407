import { useState, useRef, useContext } from "react"
import {
	Theme,
	IconButton,
	Badge,
	Box,
	ListItem,
	Hidden,
	Paper,
	Grid,
	ClickAwayListener,
} from "@mui/material"
import Icon from "@mdi/react"
import { mdiCart } from "@mdi/js"

import Link from "../i18n/Link"
import Price from "../Price"
import Button from "../Buttons/Button"

import useTranslation from "hooks/useTranslation"

import AppContext, { AppContextState } from "@secureo/common/context/AppContext/AppContext"
import mergeConfiguredLineItems from "@secureo/common/utils/commerceTools/mergeConfiguredLineItems"
import { makeStyles } from "makeStyles"

import { Cart, LineItem } from "@secureo/common/typings/Cart"

const popUpId = "cart_preview_popup"

const CartPreviewPopUp = ({
	anchorEl,
	lineItems,
	showNetPrice,
	toggleIsOpen,
}: CartPreviewPopUpProps) => {
	const { t } = useTranslation("common")
	if (!anchorEl || !anchorEl.current) return null

	const hasProducts = lineItems.length > 0

	const mergedConfiguredLineItems = mergeConfiguredLineItems(lineItems)

	return (
		<div>
			{mergedConfiguredLineItems.map((lineItem) => {
				const {
					id,
					name,
					quantity,
					taxedPrice,
					variant: { images, variantName },
					productSlug,
				} = lineItem

				const imgUrl = images[0] || "https://via.placeholder.com/60x60"

				const priceEuroCents = !showNetPrice
					? taxedPrice.totalGross.centAmount
					: taxedPrice.totalNet.centAmount

				return (
					<ListItem key={id}>
						<Link href={`/p/${productSlug}`} onClick={toggleIsOpen}>
							<Grid container spacing={1}>
								<Grid item xs={2}>
									<Box mr={1} pt={2}>
										<img
											alt={name}
											src={imgUrl}
											style={{
												width: "100%",
												minWidth: "40px",

												objectFit: "contain",
											}}
										/>
									</Box>
								</Grid>
								<Grid item xs={7}>
									<Box pt={1} style={{ fontSize: "0.9rem" }}>
										<div style={{ fontWeight: "bold" }}>
											{variantName || name}
										</div>
										<div>Anzahl: {quantity}</div>
									</Box>
								</Grid>
								<Grid item xs={3}>
									<Box pt={1} style={{ fontSize: "0.9rem", textAlign: "right" }}>
										<Price priceInEuroCents={priceEuroCents} />
									</Box>
								</Grid>
							</Grid>
						</Link>
					</ListItem>
				)
			})}
			{!hasProducts ? (
				<>
					<Box py={4} style={{ color: "#274041", textAlign: "center" }}>
						<div>{t("cartIsEmpty")}</div>
					</Box>
					<Box p={1}>
						<Link
							href="/search?query="
							style={{ textDecoration: "none" }}
							onClick={toggleIsOpen}
						>
							<Button color="primary">{t("goToProducts")}</Button>
						</Link>
					</Box>
				</>
			) : (
				<Box p={1}>
					<Link href="/cart" style={{ textDecoration: "none" }} onClick={toggleIsOpen}>
						<Button color="primary">{t("goToCart")}</Button>
					</Link>
				</Box>
			)}
		</div>
	)
}

interface CartPreviewPopUpProps {
	isOpen: boolean
	toggleIsOpen: () => void
	anchorEl: React.MutableRefObject<any>
	lineItems: Cart["lineItems"]
	showNetPrice: boolean
}

const useStyles = makeStyles()((theme: Theme) => ({
	root: {
		position: "relative",
	},
	paper: {
		zIndex: 1,
		position: "absolute",
		background: "#f7fafa",
		top: 36,
		right: 0,
		width: "345px",
	},
	fake: {
		height: theme.spacing(1),
		margin: theme.spacing(2),
		// Selects every two elements among any group of siblings.
		"&:nth-of-type(2n)": {
			marginRight: theme.spacing(3),
		},
	},
	sectionDesktop: {
		display: "none",
		[theme.breakpoints.up("md")]: {
			display: "block",
		},
	},
	// TODO: Realize this using useScreenSize hook with breakpoints
	sectionMobile: {
		[theme.breakpoints.up("md")]: {
			display: "none",
		},
	},
}))

const getNumberOfLineItems = (lineItems: LineItem[]) => {
	const lineItemsWithoutConfigurations = lineItems.filter(
		({ isConfiguration }) => !isConfiguration,
	)
	const numberOfLineItems = lineItemsWithoutConfigurations.reduce(
		(numberOfLineItems, lineItem) => numberOfLineItems + lineItem.quantity,
		0,
	)

	return numberOfLineItems
}

const CartPreview = () => {
	const { classes } = useStyles()
	const { isBusinessVersion, cart } = useContext(AppContext) as AppContextState
	const { t } = useTranslation("common")
	const popUpAnchor = useRef(null)
	const [isOpen, setIsOpen] = useState(false)

	const toggleIsOpen = () => {
		setIsOpen(!isOpen)
	}

	const close = () => {
		setIsOpen(false)
	}

	const numberOfLineItems = cart ? getNumberOfLineItems(cart.lineItems) : 0

	return (
		<>
			<div className={classes.root}>
				<ClickAwayListener onClickAway={close}>
					<div>
						<div className={classes.sectionDesktop}>
							<IconButton
								aria-label="cart"
								aria-controls={popUpId}
								onClick={toggleIsOpen}
								aria-haspopup="true"
								color="inherit"
								ref={popUpAnchor}
								style={{ padding: "2px" }}
								size="large"
							>
								<Badge badgeContent={numberOfLineItems} color="primary">
									<Icon
										path={mdiCart}
										title="Cart"
										size={1.3}
										color={!isBusinessVersion ? "#ffffff" : ""}
										style={{
											transform:
												numberOfLineItems === 69
													? "rotate(180deg)"
													: "none",
										}}
									/>
								</Badge>
							</IconButton>
						</div>
						<div className={classes.sectionMobile}>
							<IconButton
								aria-label="cart"
								aria-controls={popUpId}
								onClick={toggleIsOpen}
								aria-haspopup="true"
								color="inherit"
								ref={popUpAnchor}
								size="large"
							>
								<Badge badgeContent={numberOfLineItems} color="primary">
									<Icon
										path={mdiCart}
										title="Cart"
										size={1}
										color={!isBusinessVersion ? "#ffffff" : ""}
										style={{
											transform:
												numberOfLineItems === 69
													? "rotate(180deg)"
													: "none",
										}}
									/>
								</Badge>
							</IconButton>
						</div>
						<Hidden mdDown>
							<Box
								style={{ fontSize: "14px", cursor: "pointer" }}
								onClick={toggleIsOpen}
							>
								{t("lineItem")}
							</Box>
						</Hidden>
						{isOpen && (
							<Paper className={classes.paper}>
								<CartPreviewPopUp
									isOpen={numberOfLineItems && isOpen}
									toggleIsOpen={toggleIsOpen}
									anchorEl={popUpAnchor}
									lineItems={cart ? cart.lineItems : []}
									showNetPrice={isBusinessVersion}
								/>
							</Paper>
						)}
					</div>
				</ClickAwayListener>
			</div>
		</>
	)
}

export default CartPreview
